<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CCard v-if="!loading" class="object-table-wrapper">
        <CCardHeader>
          <strong>Сети</strong>
          <div class="card-header-actions" v-if="isAdmin">
            <CDropdown :toggler-text="$t('network.add')" color="success">
              <CDropdownItem @click="createClicked">{{
                $t("network.createNetwork")
              }}</CDropdownItem>
              <CDropdownItem @click="connectClicked">{{
                $t("network.connectNetwork")
              }}</CDropdownItem>
            </CDropdown>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            striped
            :items="allNetworks"
            :fields="fields"
            :items-per-page="10"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            :noItemsView="{
              noResults: $t('common.noData'),
              noItems: $t('common.noData'),
            }"
            @page-change="pageChange"
            hover
            clickable-rows
            @row-clicked="itemClicked"
            responsive
          >
            <template #updated="data">
              <td>
                {{ getDateFormatted(item.updated) }}
              </td>
            </template>

            <template #organizations_peer="{ item }">
              <td>
                <ul class="list-inline">
                  <li
                    v-for="(org, i) in item.consortium"
                    class="list-inline-item"
                    v-if="i < orgs_per_cell"
                    :key="i"
                  >
                    <span
                      :style="{
                        'font-style': getOrgFontStyle(org.draft_status),
                        'text-decoration': getOrgFontDecoration(
                          org.draft_status
                        ),
                      }"
                      >{{ org.mspid }}</span
                    >
                    <template
                      v-if="
                        i < item.consortium.length - 1 && i < orgs_per_cell - 1
                      "
                      >,</template
                    >
                    <template v-if="i == orgs_per_cell - 1"> ...</template>
                  </li>
                </ul>
              </td>
            </template>

            <template #organizations_orderer="{ item }">
              <td>
                <ul class="list-inline">
                  <li
                    v-for="(org, i) in item.orderer_organizations"
                    class="list-inline-item"
                    v-if="i < orgs_per_cell"
                    :key="i"
                  >
                    <span
                      :style="{
                        'font-style': getOrgFontStyle(org.draft_status),
                        'text-decoration': getOrgFontDecoration(
                          org.draft_status
                        ),
                      }"
                      >{{ org.mspid }}</span
                    >
                    <template
                      v-if="
                        i < item.orderer_organizations.length - 1 &&
                        i < orgs_per_cell - 1
                      "
                      >,</template
                    >
                    <template v-if="i == orgs_per_cell - 1"> ...</template>
                  </li>
                </ul>
              </td>
            </template>

            <template #type="{ item }">
              <td class="text-center">
                <img :src="getTypeSrc(item.version)" style="height: 24px" />
                <small class="d-block mt-1">{{ item.version }}</small>
              </td>
            </template>

            <template #update_status="{ item }">
              <td>
                <CBadge
                  v-if="item.update_status"
                  :color="netLabels[item.status][item.update_status].style"
                >
                  {{ netLabels[item.status][item.update_status].text }}
                </CBadge>

                <template
                  v-if="
                    item &&
                    item.update_status === netUpdateStatus.requested.code
                  "
                >
                  <NetworkStatus
                    :net-id="item.id"
                    @success="getBlockchainNetworks"
                    @error="getBlockchainNetworks"
                  >
                  </NetworkStatus>
                </template>
              </td>
            </template>

            <template #delete="{ item }" v-if="isAdmin">
              <td>
                <CButton
                  @click.stop.prevent="deleteNetwork(item.id)"
                  size="lg"
                  class="p-0 m-0"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
      
      <div
        v-else
        class="d-flex align-items-center justify-content-center h-100 w-100"
      >
        <fa-icon
          icon="sync"
          size="1x"
          class="animated mr-2"
          style="color: black"
        />
        <span>{{ $t("common.processing") }}...</span>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import utilMessages from "../../../utils/messages";

import orgConstants from "../organizations/constants";
import {
NetworkStatus as netStatus,
NetworkStatuses,
NetworkTypes,
NetworkUpdateStatus
} from "./constants";
import NetworkStatus from "./NetworkStatus";

export default {
  name: "Networks",
  components: { NetworkStatus },
  data() {
    const fieldsTable = [
      {
        key: "name",
        label: this.$i18n.t("network.tableName"),
        _classes: "font-weight-bold",
      },
      { key: "type", label: this.$i18n.t("network.tableType") },
      {
        key: "organizations_peer",
        label: this.$i18n.t("network.participants"),
      },
      {
        key: "organizations_orderer",
        label: this.$i18n.t("network.osnProviders"),
      },
      { key: "update_status", label: this.$i18n.t("network.status") },
      { key: "delete", label: " " },
    ];

    return {
      fields: fieldsTable,
      activePage: 1,
      orgs_per_cell: 5,
      netStatus: netStatus,
      netUpdateStatus: NetworkUpdateStatus,
      netLabels: NetworkStatuses,
      netTypes: NetworkTypes,
      orgStatus: orgConstants.draftStatus,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;

    try {
      await this.getBlockchainNetworks();

      if (!this.isAdmin) {
        await this.itemClicked({ id: this.getUser.blockchain_net_id });
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(["allNetworks", "getUser", "isAdmin", "getNetwork"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getDateFormatted(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm");
    },
    getTypeSrc(netType) {
      switch (netType) {
        case this.netTypes.GOSMART10.code:
          return this.netTypes.GOSMART10.src;
        case this.netTypes.HLF20.code:
          return this.netTypes.HLF20.src;
        case this.netTypes.HLF14.code:
          return this.netTypes.HLF14.src;
        case this.netTypes.HLF22.code:
          return this.netTypes.HLF22.src;
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    async itemClicked(item, index) {
      if (this.isAdmin) {
        try {
          await this.$store.dispatch("switchBlockchain", item.id);
          this.$toast.success(this.$i18n.t("toasts.organizationChanged"));
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }

      const clickedNetwork = this.getNetwork(item.id);
      if (!clickedNetwork) {
        this.$router.push({ path: "/dashboard" });
        this.$toast.error(
          `${this.$i18n.t("network.networkNotFound")} (${item.id})`
        );
      }

      if (clickedNetwork && !!clickedNetwork.config_update_transaction_id) {
        this.$router.push({ path: `/networks/${item.id}/change-config` });
        return;
      }

      const path =
        clickedNetwork.update_status === this.netUpdateStatus.success.code
          ? `/networks/${item.id}`
          : `/networks/${item.id}/draft`;

      this.$router.push({ path });
    },
    createClicked() {
      this.$router.push({ path: `/networks/create` });
    },
    connectClicked() {
      this.$router.push({ path: `/networks/connect` });
    },
    async getBlockchainNetworks() {
      try {
        await this.$store.dispatch("fetchNetworks", {
          view: this.isAdmin ? "msp" : "organization",
        });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getOrgFontStyle(orgStatus) {
      let fontStyle = "";
      if (orgStatus === this.orgStatus.added.code) fontStyle = "italic";

      return fontStyle;
    },
    getOrgFontDecoration(orgStatus) {
      let fontDecoration = "";
      if (orgStatus === this.orgStatus.deleted.code)
        fontDecoration = "line-through";

      return fontDecoration;
    },
    async deleteNetwork(netId) {
      try {
        await this.$store.dispatch("deleteNetwork", netId);
        this.$toast.success(this.$i18n.t("network.networkDeleted"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      } finally {
        await this.getBlockchainNetworks();
      }
    },
  },
};
</script>
