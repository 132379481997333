<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <HostWS
    v-if="topicId"
    :topic-id="topicId"
    :showIcon="false"
    @message="checkResult($event)"
    @error="checkResult($event)"
  ></HostWS>
</template>

<script>
import HostWS from "../components/HostWS";

export default {
  name: "NetworkStatus",
  components: { HostWS },
  props: {
    netId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      topicId: null,
    };
  },
  async mounted() {
    await this.fetchTopicId();
  },
  methods: {
    async fetchTopicId() {
      const { topic_id } = await this.$store.dispatch(
        "fetchTopicByNet",
        this.netId
      );
      this.topicId = topic_id;
    },
    checkResult(wsResult) {
      this.$emit(wsResult.result === "success" ? "success" : "error");
    },
  },
};
</script>
