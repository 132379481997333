var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',[(!_vm.loading)?_c('CCard',{staticClass:"object-table-wrapper"},[_c('CCardHeader',[_c('strong',[_vm._v("Сети")]),(_vm.isAdmin)?_c('div',{staticClass:"card-header-actions"},[_c('CDropdown',{attrs:{"toggler-text":_vm.$t('network.add'),"color":"success"}},[_c('CDropdownItem',{on:{"click":_vm.createClicked}},[_vm._v(_vm._s(_vm.$t("network.createNetwork")))]),_c('CDropdownItem',{on:{"click":_vm.connectClicked}},[_vm._v(_vm._s(_vm.$t("network.connectNetwork")))])],1)],1):_vm._e()]),_c('CCardBody',[_c('CDataTable',{attrs:{"striped":"","items":_vm.allNetworks,"fields":_vm.fields,"items-per-page":10,"active-page":_vm.activePage,"pagination":{ doubleArrows: false, align: 'center' },"noItemsView":{
            noResults: _vm.$t('common.noData'),
            noItems: _vm.$t('common.noData'),
          },"hover":"","clickable-rows":"","responsive":""},on:{"page-change":_vm.pageChange,"row-clicked":_vm.itemClicked},scopedSlots:_vm._u([{key:"updated",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(_vm.getDateFormatted(_vm.item.updated))+" ")])]}},{key:"organizations_peer",fn:function({ item }){return [_c('td',[_c('ul',{staticClass:"list-inline"},_vm._l((item.consortium),function(org,i){return (i < _vm.orgs_per_cell)?_c('li',{key:i,staticClass:"list-inline-item"},[_c('span',{style:({
                      'font-style': _vm.getOrgFontStyle(org.draft_status),
                      'text-decoration': _vm.getOrgFontDecoration(
                        org.draft_status
                      ),
                    })},[_vm._v(_vm._s(org.mspid))]),(
                      i < item.consortium.length - 1 && i < _vm.orgs_per_cell - 1
                    )?[_vm._v(",")]:_vm._e(),(i == _vm.orgs_per_cell - 1)?[_vm._v(" ...")]:_vm._e()],2):_vm._e()}),0)])]}},{key:"organizations_orderer",fn:function({ item }){return [_c('td',[_c('ul',{staticClass:"list-inline"},_vm._l((item.orderer_organizations),function(org,i){return (i < _vm.orgs_per_cell)?_c('li',{key:i,staticClass:"list-inline-item"},[_c('span',{style:({
                      'font-style': _vm.getOrgFontStyle(org.draft_status),
                      'text-decoration': _vm.getOrgFontDecoration(
                        org.draft_status
                      ),
                    })},[_vm._v(_vm._s(org.mspid))]),(
                      i < item.orderer_organizations.length - 1 &&
                      i < _vm.orgs_per_cell - 1
                    )?[_vm._v(",")]:_vm._e(),(i == _vm.orgs_per_cell - 1)?[_vm._v(" ...")]:_vm._e()],2):_vm._e()}),0)])]}},{key:"type",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('img',{staticStyle:{"height":"24px"},attrs:{"src":_vm.getTypeSrc(item.version)}}),_c('small',{staticClass:"d-block mt-1"},[_vm._v(_vm._s(item.version))])])]}},{key:"update_status",fn:function({ item }){return [_c('td',[(item.update_status)?_c('CBadge',{attrs:{"color":_vm.netLabels[item.status][item.update_status].style}},[_vm._v(" "+_vm._s(_vm.netLabels[item.status][item.update_status].text)+" ")]):_vm._e(),(
                  item &&
                  item.update_status === _vm.netUpdateStatus.requested.code
                )?[_c('NetworkStatus',{attrs:{"net-id":item.id},on:{"success":_vm.getBlockchainNetworks,"error":_vm.getBlockchainNetworks}})]:_vm._e()],2)]}},(_vm.isAdmin)?{key:"delete",fn:function({ item }){return [_c('td',[_c('CButton',{staticClass:"p-0 m-0",attrs:{"size":"lg"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteNetwork(item.id)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}:null],null,true)})],1)],1):_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100 w-100"},[_c('fa-icon',{staticClass:"animated mr-2",staticStyle:{"color":"black"},attrs:{"icon":"sync","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$t("common.processing"))+"...")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }